body {
  margin: 0;
  height: 100vh;
}

.blur {
  filter: blur(12px);
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

/* Style Instances */
#UnlockInstantPricingModal {
  z-index: 99;
  left: 380px;
  top: 40%;
}

/* Amplify Styles*/
.amplify-collection {
  align-self: center;
}

.amplify-flex.amplify-collection-search {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.amplify-textfield {
  max-width: 500px;
  width: 100%;
  color: white;
}

/* Google Styles*/
.google-map-container {
  min-height: 260px;
  width: 100%;
  border-radius: 12px;
}
.gmnoprint a,
.gmnoprint span {
  display: none;
}
.gmnoprint div {
  background: none !important;
}
.gm-style-cc {
  display: none;
}

.lightMode {
  color: #81848f;
  background: #fff;
  width: 100%;
  max-width: 88%;
  border: 2px solid rgb(237, 235, 235);
  padding: 12px;
  border-radius: 10px;
}

.lightMode:focus {
  outline: none !important;
  border: 2px solid #096bde;
}

[data-reach-combobox-list]:focus {
  background: rgb(255, 255, 255);
  color: rgb(2, 2, 2);
}

.darkMode {
  background: #000;
  color: #fff;
  width: 100%;
  max-width: 88%;
  border: 2px solid #26252d;
  padding: 12px;
  border-radius: 10px;
}

.darkMode:focus {
  outline: none !important;
  border: 2px solid #096bde;
}

/* JOI UI */
.JoyTooltip-root {
  z-index: 999999 !important;
}
